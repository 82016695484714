import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { OtherXseriesModelsProps } from "./XseriesModel.d"
import { Image } from "../../atoms/Image"
import { LocationContext } from "../../../contexts/Location"
import { InventoryClient } from "../../../clients/InventoryClient"
import { LocalInventoryLinks } from "../../molecules/LocalInventoryLinks"
import { css } from "@emotion/react"
import { Link } from "../../atoms/Link"
import { generateInventoryLink } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const OtherXseriesModels: React.FC<OtherXseriesModelsProps> = ({
  models,
  customModels,
}) => {
  const { vehicleTealData } = useTealiumContext()
  const model = customModels[0]
  let groupedModels: any = {}
  const currentSlug =
    typeof window !== "undefined" && window?.location?.pathname?.split("/")[2]
  //group the models as an array where the series.name is the same
  //filter out the ones that match the current slug
  models = models.filter((m: any) => m.series.slug !== currentSlug)
  if (models.length > 0) {
    groupedModels = models.reduce((r, a) => {
      r[a.series.name] = [...(r[a.series.name] || []), a]
      return r
    }, {})
  }
  //this function is just a prelimary way to get images by angle and a color -- will need some revisions but works for now
  const getExteriorImageByColorAndAngle = (
    colorCode: string,
    model: any,
    angle: number
  ) => {
    //get the color from the colors object where exterior.code is the same as the color
    const exteriorColor = model.colors.find(
      (c: { exterior: { code: string } }) => c.exterior.code === colorCode
    )

    if (!exteriorColor) {
      //if we dont have a color that matches the color code supplied then take the first one
      return model.colors[0].exteriorImages[angle]
    }
    return exteriorColor?.exteriorImages
      ? exteriorColor?.exteriorImages[angle]
      : model.jellybean
  }
  const { _ } = useContext(LanguageContext)
  const [{ city, dealers }] = useContext(LocationContext)
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const hoverAnimation = css`
    &:hover > .darkRed {
      ${tw`translate-x-11 duration-300`}
    }
    &:hover > .lighterRed {
      ${tw`opacity-100 delay-200`}
    }
    &:hover > .lightestRed {
      ${tw`opacity-100 delay-100`}
    }
  `

  useEffect(() => {
    const getCount = async () => {
      const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
      try {
        setInventoryCount("...")
        const { count } = await InventoryClient.getCount("", {
          dealer: dealerIds,
          accessoryModels: [model.accessoryCodes],
        })
        setInventoryCount(count)
      } catch (error) {
        setInventoryCount(error)
      }
    }
    getCount()
  }, [dealers])

  return (
    <>
      <div>
        <div css={[tw` p-4 md:(max-w-desktop mx-auto)`]}>
          <LocalInventoryLinks
            city={city}
            dealerCount={dealers?.length}
            inventoryCount={inventoryCount}
            inventoryLink={generateInventoryLink({
              series: model.series?.name.toLowerCase(),
              accessoryModels: [model.accessoryCodes],
            })}
            series={vehicleTealData?.vehicle_model}
            extraTealiumValues={{
              vehicle_segment_vehicle_page:
                vehicleTealData?.vehicle_segment_vehicle_page,
              vehicle_year: vehicleTealData?.vehicle_year,
            }}
          />
        </div>
        <ul
          css={[
            tw`grid p-4 overflow-hidden grid-cols-12 mx-auto grid-rows-1 grid-flow-row w-auto h-auto md:(max-w-desktop gap-20 mb-20)`,
          ]}
        >
          <li
            css={[
              tw`col-span-12 flex justify-center items-center md:(col-span-3) `,
            ]}
          >
            <h2
              css={tw`text-xl tracking-widest text-left pb-4 font-light md:(text-4xl)`}
            >
              {_("Other XSeries Models")}
            </h2>
          </li>
          {groupedModels &&
            Object.entries(groupedModels).map(([key, value]) => (
              <li
                css={[
                  tw`col-span-12 list-none flex justify-center items-center pb-4 md:(grid col-span-3 pb-0)`,
                ]}
              >
                <div css={[tw`w-2/5 md:(order-2 w-full mx-auto ml-5)`]}>
                  <h3 css={[tw`font-light text-lg uppercase`]}>{key}</h3>
                  {value.map((model: any) => (
                    <h4 css={[tw`font-semibold text-lg`]}>
                      <Link
                        to={`/xseries/${model?.series?.slug}`}
                        state={{ modelName: model.name }}
                        css={[tw`relative p-0`, hoverAnimation]}
                      >
                        {model.name}
                        <Icon.Chevron
                          direction="right"
                          color="red-200"
                          className="lightestRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 opacity-0`,
                          ]}
                        />
                        <Icon.Chevron
                          direction="right"
                          color="red-300"
                          className="lighterRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-8 -translate-y-0.5 opacity-0`,
                          ]}
                        />
                        <Icon.Chevron
                          direction="right"
                          color="red-400"
                          className="darkRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 duration-300`,
                          ]}
                        />
                      </Link>
                    </h4>
                  ))}
                </div>
                <div css={[tw`w-3/5 md:(w-full order-1)`]}>
                  <Image
                    imageData={getExteriorImageByColorAndAngle(
                      "0040",
                      value[0],
                      1
                    )}
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export default OtherXseriesModels
