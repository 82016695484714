import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import tw from "twin.macro"
import XseriesVideo from "../components/organisms/XseriesModel/Video"
import { Pixlee } from "../components/molecules/Pixlee"
import OtherXseriesModels from "../components/organisms/XseriesModel/OtherModels"
import XSeriesModelDetails from "../components/organisms/XseriesModel/Details"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { sanitizeSeries, vehicleCategory } from "../helpers"
import { DisclaimersContext } from "../contexts/Disclaimers"
import { setDisclaimers } from "../contexts/Disclaimers/actions"
import { LanguageContext } from "../contexts/Language"
import _ from "lodash"
import { Disclaimer } from "../contexts/Disclaimers/disclaimers.d"
import { useTealiumContext } from "../contexts/Tealium"
import XSeriesFamilySeries from "../components/organisms/Xseries/FamilySeries"

const XSeriesModelPage: React.FC<any> = ({ data, location }) => {
  const { page } = data
  const [{ disclaimers }, dispatch] = useContext(DisclaimersContext)
  const { language } = useContext(LanguageContext)

  let model = ""
  let modelPosition = 0

  if (location?.state?.modelName) {
    model = page?.customModels.find(
      (model: any) => model.name === location?.state?.modelName
    )
    modelPosition = page?.customModels.findIndex(
      (model: any) => model.name === location?.state?.modelName
    )
  } else {
    model = page?.customModels[0]
    modelPosition = 0
  }

  useEffect(() => {
    let modelDisclaimers = model?.disclaimers?.highlightedFeatures
    modelDisclaimers.forEach((disclaimer: any) => {
      disclaimer.disclaimer =
        language === "es" ? disclaimer.disclaimer_es : disclaimer.disclaimer_en
    })

    const containsAllModalDisclaimers = modelDisclaimers.every(
      (disclaimer: any) =>
        disclaimers.some(
          (existingDisclaimer: Disclaimer) =>
            existingDisclaimer.code === disclaimer.code
        )
    )
    if (!containsAllModalDisclaimers) {
      const updatedDisclaimers = _.unionBy(
        modelDisclaimers,
        disclaimers,
        "code"
      )
      dispatch(setDisclaimers(updatedDisclaimers))
    }
  }, [disclaimers])

  // Tealium
  const { handleTealView } = useTealiumView()
  const { setVehicleTealData } = useTealiumContext()

  useEffect(() => {
    //const selectedModel = page?.customModels[0]
    const selectedModel =
      page?.customModels.find(
        (model: any) => model.name === location?.state?.modelName
      ) || page?.customModels[0]
    const series = selectedModel?.series?.name
    const serieslc = sanitizeSeries(series)
    const model = selectedModel?.name
    const series_model = `${series} ${model}`
    const category = vehicleCategory(serieslc)

    handleTealView({
      page_type: "x-series",
      vehicle_model: series_model,
      tealium_event: "x-series_lander",
      page_name: `${category}_${series_model.toLocaleLowerCase()}`,
      vehicle_year: selectedModel?.year,
      vehicle_segment_vehicle_page: `${category}`,
      vehicle_model_truncated: series_model,
    })
    setVehicleTealData({
      vehicle_model: series_model,
      vehicle_year: selectedModel?.year,
      vehicle_segment_vehicle_page: `${category}`,
      vehicle_model_truncated: series_model,
      vehicle_model_trim: series_model,
      vehicle_series: series,
    })
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <XSeriesFamilySeries
        selectedModel={location?.state?.modelName}
        {...page}
      />
      <div css={[tw`bg-gradient-to-t from-gray-200 via-gray-200 to-gray-50`]}>
        <XSeriesModelDetails modelPosition={modelPosition} {...page} />
        <OtherXseriesModels {...page} />
        <XseriesVideo {...page} />
        {page.pixlee?.albumId ? (
          <Pixlee albumId={page.pixlee?.albumId} />
        ) : null}
        {/* <OthersAlsoBought {...page} /> REMOVED PER PIVOTAL TICKET# #184093070 */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query XSeriesModelPageQuery($id: String) {
    page: sanityPageXseriesModel(id: { eq: $id }) {
      id
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      language
      tagline
      disclaimers {
        code
        disclaimer
      }
      customModels {
        ... on SanityCustomModel {
          id
          year
          tagline
          taglineES
          accessoryCodes
          jellybean {
            asset {
              altText
              _id
              gatsbyImageData(placeholder: NONE)
            }
          }
          name
          series {
            ...seriesPageModelSeries
          }
          colors {
            ...color
          }
          costs {
            baseMSRP
          }
          highlightedFeatures {
            title
            language
          }
          badgeImage {
            asset {
              altText
              _id
              gatsbyImageData
            }
          }
          disclaimers {
            highlightedFeatures {
              code
              disclaimer_en
              disclaimer_es
            }
          }
          hotspotsAngle1 {
            _key
            _type
            title
            titleES
            description
            descriptionES
            x
            y
            image {
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
          hotspotsAngle2 {
            _key
            _type
            title
            titleES
            description
            descriptionES
            x
            y
            image {
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
          hotspotsAngle3 {
            _key
            _type
            title
            titleES
            description
            descriptionES
            x
            y
            image {
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
          hotspotsAngle4 {
            _key
            _type
            title
            titleES
            description
            descriptionES
            x
            y
            image {
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
        }
      }
      models {
        ... on SanityCustomModel {
          id
          year
          jellybean {
            asset {
              altText
              _id
              gatsbyImageData(placeholder: NONE)
            }
          }
          name
          series {
            ...seriesPageModelSeries
          }
          colors {
            ...color
          }
          costs {
            baseMSRP
          }
          accessoryCodes
        }
      }
      pixlee {
        albumId
      }
      othersAlsoBought {
        _key
        heading
        body
        imageLink {
          _type
          linkType
          displayText
          internalLink {
            slug {
              _type
              current
            }
          }
        }
        image {
          ...SanityImageData
        }
      }
      video {
        heading
        body
        image {
          ...SanityImageData
        }
        externalMedia {
          embedUrl
        }
      }
    }
  }
`

export default XSeriesModelPage
