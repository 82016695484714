import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { Color, Model } from "../../../global"
import { parseDisclaimerBlocks } from "../../../helpers"
import { ColorSelector } from "../../molecules/ColorSelector"
import { ModelImageViewerWithHotSpots } from "../../molecules/ModelImageViewerWithHotSpots"
import { Hotspot, XSeriesModelDetailsProps } from "./XseriesModel.d"
import { Image } from "../../atoms/Image"
import { XseriesHotspots } from "../../molecules/XseriesHotspots"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { css } from "@emotion/react"
import { useTealiumContext } from "../../../contexts/Tealium"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesModelDetails: React.FC<XSeriesModelDetailsProps> = ({
  customModels,
  tagline,
  modelPosition,
}) => {
  const { language } = useContext(LanguageContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { updateVehicleTealData } = useTealiumContext()
  const [selectedColor, setSelectedColor] = useState<number>(0)
  const [activeSpot, setActiveSpot] = useState<Hotspot>()
  const [currentAngle, setCurrentAngle] = useState<1 | 2 | 3 | 4>(1)
  const [activeModel, setActiveModel] = useState(customModels[modelPosition])
  const [exteriorColorList, setExteriorColorList] = useState(null)
  const [exteriorImages, setExteriorImages] = useState(null)
  const [hotspots, setHotspots] = useState<Hotspot[]>([])
  const [currentAngleHotspots, setCurrentAngleHotspots] = useState<Hotspot[]>(
    []
  )

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `
  useEffect(() => {
    const updatedExteriorColorList = activeModel?.colors?.map((color: any) => {
      return {
        name: color?.exterior?.title,
        hex: color?.exterior?.colors?.map((color: any) => color?.hex),
      }
    })

    const updatedExteriorImages = activeModel?.colors?.map((color: any) => {
      return color?.exteriorImages
    })
    setExteriorColorList(updatedExteriorColorList)
    setExteriorImages(updatedExteriorImages)

    // Setting hotspots
    const angle1Hotspots = activeModel?.hotspotsAngle1?.map((hotspot: any) => {
      hotspot.angle = 1
      return hotspot
    })
    const angle2Hotspots = activeModel?.hotspotsAngle2?.map((hotspot: any) => {
      hotspot.angle = 2
      return hotspot
    })
    const angle3Hotspots = activeModel?.hotspotsAngle3?.map((hotspot: any) => {
      hotspot.angle = 3
      return hotspot
    })
    const angle4Hotspots = activeModel?.hotspotsAngle4?.map((hotspot: any) => {
      hotspot.angle = 4
      return hotspot
    })
    setHotspots([
      ...angle1Hotspots,
      ...angle2Hotspots,
      ...angle3Hotspots,
      ...angle4Hotspots,
    ])

    updateVehicleTealData({ vehicle_model_trim: activeModel?.name })
  }, [activeModel])

  useEffect(() => {
    // Setting hotspots for current angle
    let newCurrentAngleHotspots = []
    switch (currentAngle) {
      case 1:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle1?.map(
          (hotspot: any) => {
            hotspot.angle = 1
            return hotspot
          }
        )
        break
      case 2:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle2?.map(
          (hotspot: any) => {
            hotspot.angle = 2
            return hotspot
          }
        )
        break
      case 3:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle3?.map(
          (hotspot: any) => {
            hotspot.angle = 3
            return hotspot
          }
        )
        break
      case 4:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle4?.map(
          (hotspot: any) => {
            hotspot.angle = 4
            return hotspot
          }
        )
        break
      default:
        break
    }
    setCurrentAngleHotspots(newCurrentAngleHotspots)
  }, [currentAngle, activeModel])

  return (
    <>
      <div
        css={[
          tw`grid grid-cols-12 mx-auto grid-rows-1 grid-flow-row w-auto h-auto`,
          tw`lg:(max-w-full pb-0 )`,
        ]}
      >
        <h3 css={[tw`m-8 col-span-12 italic text-gray-600 tracking-widest`]}>
          {tagline}
        </h3>
        <div
          css={[
            tw`col-span-12 p-8`,
            tw`lg:(col-span-4 bg-white pb-16 rounded-tr-3xl shadow-5 mb-20)`,
          ]}
        >
          <h3 css={[tw`uppercase text-2xl text-center font-semibold`]}>
            {customModels?.length > 1 &&
              customModels?.map((model: Model) => {
                return (
                  <button
                    onClick={() => {
                      setActiveModel(model)
                    }}
                    aria-selected={activeModel === model}
                    role="tab"
                    css={[
                      tabAnimation,
                      focusAnimation,
                      tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2 border border-transparent`,
                      tw`focus-visible:(border-gray-900 border-dashed border outline-none)`,
                      activeModel === model && active,
                    ]}
                  >
                    {model?.name}
                  </button>
                )
              })}
          </h3>
          <h3
            css={[
              tw`uppercase text-6xl italic text-center block font-semibold pb-8`,
            ]}
          >
            {/* {model.name} */}
            <Image imageData={activeModel?.badgeImage} />
          </h3>
          <div css={[tw`flex flex-col`]}>
            <div css={[tw`order-2 mt-8`, tw`lg:(order-1 mt-0)`]}>
              {exteriorColorList && (
                <ColorSelector
                  colorList={exteriorColorList}
                  onChange={selectedSwatch => setSelectedColor(selectedSwatch)}
                  xpSeries
                  analyticsId={`exterior color selector:xseries features:`}
                />
              )}
            </div>
            <div css={[tw`order-1`, tw`lg:(order-2)`]}>
              <p css={[tw`py-8`]}>
                {language === "es"
                  ? parseDisclaimerBlocks(activeModel?.taglineES, selection =>
                      modalDispatch(toggleDisclaimersModal(selection))
                    )
                  : parseDisclaimerBlocks(activeModel?.tagline, selection =>
                      modalDispatch(toggleDisclaimersModal(selection))
                    )}
              </p>
              <ul css={[tw`list-disc pl-6`]}>
                {activeModel?.highlightedFeatures
                  .filter(feature => feature?.language === language)
                  .map(({ title }, i) => (
                    <li
                      key={`feature-${i}`}
                      css={[tw`font-semibold mb-3 text-sm`]}
                    >
                      {parseDisclaimerBlocks(title, selection =>
                        modalDispatch(toggleDisclaimersModal(selection))
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div css={[tw`col-span-12 lg:(col-start-5 col-span-8 mx-10)`]}>
          <div>
            <div>
              {exteriorImages && (
                <ModelImageViewerWithHotSpots
                  images={exteriorImages[selectedColor]}
                  currentSlideHotspots={currentAngleHotspots}
                  currentColor={exteriorColorList[selectedColor].name}
                  activeSpot={activeSpot}
                  setActiveSpot={setActiveSpot}
                  setCurrentAngle={setCurrentAngle}
                  currentAngle={currentAngle}
                  hotspots={hotspots}
                  xpSeries
                />
              )}
              {hotspots && (
                <XseriesHotspots
                  hotspots={hotspots}
                  activeSpot={activeSpot}
                  setActiveSpot={setActiveSpot}
                  currentAngle={currentAngle}
                  setCurrentAngle={setCurrentAngle}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default XSeriesModelDetails
